import React from 'react';
import ATLTPIMG from '../../assets/images/platform/ATLTPIMG.png'
import ATPBASIMG from '../../assets/images/platform/ATPBASIMG.png'
import Retargeting from '../../assets/images/platform/retargeting.png'
import GFATIMG from '../../assets/images/platform/GFATIMG.png'
import LATIMG from '../../assets/images/platform/LATIMG.png'
import CATIMG from '../../assets/images/platform/CATIMG.png'
import RPCATIMG from '../../assets/images/platform/RPCATIMG.png'
import ARPIMG from '../../assets/images/platform/audience-reach-panel.webp'

function AudienceTargetingSec() {
    return (
        <>
            <div class="InnerBSep"></div>
            <section className='ATSec bg-white'>
                <div className='container'>

                <div className='row'>
                        <div className='col-lg-12'>
                            <div className='discription'>
                            <div class="text-left">
                                <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Unmatched Audience Reach and Targeting</h2>
                            </div>

                            <p>Our unique combination of top Data and Analytics products with a world class DSP Platform provides you with the ability to target 91.5 crore Indian adults in a residence-precise location covering several points of data for each unique individual including:</p>
                            <ul className="">
                                <li className="aK6P7">
                                    
                                    <p>Psychography and lifestyle, income, household expenditure under several heads, vehicle ownership, healthcare attitude, savings, investment, insurance and jewelry profile, property type, etc. through our preferred partner Kentrix.
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Behavioral and personal aspects like shopping for various items by brand, F&B habits, medical/healthcare, entertainment and arts, movies, travelers, credit card users, public transport users, professionals, students and so on through our other data
partnerships.
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className=''>
                                <img src={ARPIMG} alt='' width="100%" />
                            </div>
                        </div>
                    </div>

                <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='discription'>
                            <div class="text-left">
                                <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Audience Targeting from Any Data Provider</h2>
                            </div>

                            <p>Programmatic India provides you with the ability to work with any data provider you choose. Upload CSV, integrate through your CRM or ingest data through your data providers.</p>
                            <ul className="">
                                <li className="aK6P7">
                                    
                                    <p>
                                    Flexible data provider options
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Upload data in any format and from any system
                                    </p>
                                </li>
                                <li className="aK6P7">
                                    
                                    <p>
                                    Seamless integration with multiple providers
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={ATLTPIMG} alt='' width="100%" />
                            </div>
                        </div>
                    </div>


                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row divReverse'>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={ATPBASIMG} alt='' width="100%"  />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Pre-built Audience Segments Targeting</h2>
                                </div>
                                <p>Programmatic India offers an extensive library of curated segments that are readily available for activation. You can instantly choose from thousands of these segments, which have been precisely targeted to reach specific audiences.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Meticulously curated Segments by our experts
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Activate these segments within minutes
                                    </p>
                                </li>
                                
                            </ul>
                            </div>
                        </div>

                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Retargeting</h2>
                                </div>
                                <p>Using PI's advanced retargeting technology, you can re-engage with your website visitors. Our platform tracks their online behavior and displays your ads to them across multiple channels and platforms.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Stay top-of-mind and bring your users back to your site.
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Increasing the chances of converting your users to your customers.
                                    </p>
                                </li> 
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={Retargeting} alt='' width="100%"  />
                            </div>
                        </div>
                    </div>

                </div>
            </section>

            <section className='ATSec gradientGrayBg'>
                <div className='container'>
                <div className='row divReverse'>
                    <div className='col-lg-6'>
                            <div className=''>
                                <img src={GFATIMG} alt='' width="100%"  />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Geo-farmed Audience Targeting</h2>
                                </div>
                                <p>By leveraging PI's proprietary location data technology, you can target people based on their historical location visitation behavior.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Identify audiences based on their visitations of specific locations such as office zones or workspaces.
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Create a customised segment of these audiences
                                    </p>
                                </li> 
                                <li className="aK6P7"> 
                                    <p>
                                    Reach out to these audiences on mobiles or desktops with a customized message.
                                    </p>
                                </li> 
                            </ul>
                            </div>
                        </div>
                        
                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>
                    
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Lookalike Audience Targeting</h2>
                                </div>
                                <p>With Programmatic India, you can expand your reach and connect with new people who share similar characteristics and behaviors to your target audiences.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Identify patterns in user's demographics, interests, and online behavior.
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Build custom audience segments of users who exhibit similar traits
                                    </p>
                                </li> 
                                <li className="aK6P7"> 
                                    <p>
                                    Expand your reach to a larger audience
                                    </p>
                                </li> 
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={LATIMG} alt='' width="100%"  />
                            </div>
                        </div>
                        
                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row divReverse'>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={CATIMG} alt='' width="100%"  />
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Contextual Audience Targeting</h2>
                                </div>
                                <p>With PI's advanced technology, you can target users based on the content they are consuming or have consumed in the past. We support 100+ languages and we catalogue 250k+ publilshers and 150M+ pages daily.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Analyzes user's online behavior
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Identify users interested in specific topics
                                    </p>
                                </li> 
                                <li className="aK6P7"> 
                                    <p>
                                    Categorizes users into different interest groups and target them.
                                    </p>
                                </li> 
                            </ul>
                            </div>
                        </div>
                        
                        
                    </div>

                    <div className="gnagt">
                        <hr className="" />
                    </div>

                    <div className='row'>
                        
                        <div className='col-lg-6'>
                            <div className='discription'>
                                <div class="text-left">
                                    <h2 class="display-5 fw-bold primary-title mb-4 otherpageTitle">Repeat Previous Campaign Audience to achieve mass media like frequency</h2>
                                </div>
                                <p>With Programmatic India, you can create a dynamic campaign funnel by targeting users who have engaged with your ads in the past. This means you can tailor your ad campaigns specifically to those users who have already shown interest in your brand, increasing the likelihood of conversion.</p>
                                <ul className="">
                                <li className="aK6P7">
                                    <p>
                                    Target users who engaged with ads in past
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Tailor ad campaigns to interested users
                                    </p>
                                </li> 
                                <li className="aK6P7"> 
                                    <p>
                                    Increases likelihood of conversion
                                    </p>
                                </li>
                                <li className="aK6P7"> 
                                    <p>
                                    Retarget interested users to keep brand top of mind
                                    </p>
                                </li> 
                            </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className=''>
                                <img src={RPCATIMG} alt='' width="100%"  />
                            </div>
                        </div>
                        
                        
                    </div>
                </div>
            </section>

           
        </>
    );
}

export default AudienceTargetingSec;
