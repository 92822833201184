import React from 'react';
import ExploreAudienceBackImg from '../assets/images/ExploreAudience-back-img.png'
import ExploreAudienceFrontImg from '../assets/images/ExploreAudience-front-img.png'
import { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';

export default function ExploreAudience() {

    return (
        <>
            <div className='container'>
                <div className='row'>
                <div className='col-lg-12 col-12'>
                        <div className='text-center'>
                            {/* <h3 className='fw-bold primary-title'>With Programmatic India, you can</h3> */}
                            <h4 className="display-5 fw-bold primary-title mb-5">The <span>Most Extensive</span> range of audience<br className='break' /> segments available in India </h4>
                        </div>
                    </div>
                    <div className='col-lg-8 col-sm-8  col-xs-12'>
                    <div className='section-title d-sm-none'>
                            <h2 className='display-4 fw-bold lightgreen'>Unmatched Data</h2>
                        </div>
                        <div className="overlap-end position-relative">
                            <div className="ratio ratio-4x3">
                                <img src={ExploreAudienceBackImg} className='backImg backshadow border1' alt='' />
                                <div className='borderLayout top'></div>
                            </div>
                            <div className="overlap-content col-lg-7 col-6">
                                <div className='videoRatio'>
                                <video
                                    className="img-fluid shadow-md fullBorder shadow-md bg-primary-5"
                                    autoPlay=""
                                    loop=""
                                    muted=""
                                    playsInline=""
                                    controlslist="nodownload"
                                    poster={ExploreAudienceFrontImg}
                                    >
                                    <source
                                        src=""
                                        type="video/webm"
                                    />
                                    <source
                                        src=""
                                        type="video/mp4"
                                    />
                                </video>
                                <div className='borderLayout bottom'></div>
                                </div>
                                
                            </div>
                        </div>

                    </div>
                    <div className='col-lg-4 col-sm-4 col-xs-12 centerElements'>
                        <div className='section-title d-none d-lg-block '>
                            <h2 className='display-4 fw-bold lightgreen'>Unmatched Data</h2>
                        </div>
                        <p className='mt-2'>Our unique data partners enable unlimited audience segment customization of 91.5 crore population, the largest and most detailed than any other platform.</p>
                        <Link to="/solutions/targeting/audience-targeting" class="main-btn btn mt-2">Know More</Link>
                    </div>
                </div>
                <div className="py-0 py-lg-4 my-3 bg-transparent"></div>
            </div>
        </>
    )
}

